.App {
  text-align: center;
}

.headline-text {
  text-align: center;
  color: rgb(1, 76, 140);
  font-size: 22.5px;

}

