
.Clock {
    text-align: right;
    background-color: blue;
    color: yellow;
    font-size: x-large;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 2px;
}
