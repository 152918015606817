.mymap {
    position: fixed;
    top: 150px;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
}

.leaflet-container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

.marker {
    border-radius: 50%;
    background-color: #0000B0;
    color: white;
    font-size: 10px;
    padding: 5px;
    font-family: Lato;
    font-weight: 600;
}

.marker-early {
    background-color: rgb(204, 0, 0);
    z-index: 1000;
}

.marker-late {
    background-color: rgb(55, 96, 146);
    z-index: 1000;
}

.marker-transfer {
    background-color: rgb(252, 187, 2);
    z-index: 1000;
    color: #000;
}

.marker-intime {
    background-color: rgb(0, 128, 0);
    z-index: 1000;
}

.marker-gray {
    background-color: gray;
    z-index: 1000;
}

.marker-purple {
    background-color: rgb(128, 100, 162);
    z-index: 1000;
}

.popup {
    z-index: 1001;
}

.popup-early {
    color: rgb(204, 0, 0);
}

.popup-intime {
}


.popup-late {
    color: rgb(55, 96, 146);
}

.btnSize {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1031;
    font-size: 22px;
    border: 2px solid #000;
    background-color: #fff;
    border-radius: 4px;
    padding:  2px 6px 2px 6px;
    float: right;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.2);
    outline: currentColor none medium;
}

.btnSize:hover {
    background-color: #f4f4f4;
}

.mymap-sub {
    position: absolute;
    width: 100%;
    height: 100%;
}

.mapFull {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 99;
}

.mapSmall {
    position: absolute;
    z-index: 1031;
    right: 0px;
    top: 0;
    margin-top: 190px;
    bottom: 0px;
    left: 0;
    margin-left: 0px;
}

#lineList2 {
    list-style: none;
}

.fa-sort-down, .fa-sort-up {
    font-size: 1.5em;
    color: #fff;
    background-color: rgb(63, 110, 167);
    padding: 5px;
    border-radius: 5px;
    z-index: 1000;
    margin-top: -6px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.sidenav {
    max-height: 0vh;
    width: 180px;
    position: absolute;
    z-index: 999;
    right: 0;
    background-color: #fff;
    transition: 0.5s;
    margin-top: -40px;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidenav li {
    width: 180px;
    list-style: none;
    padding: 6px 6px 6px 0px;
    border-top: 1px solid #d9d9d9;
    text-decoration: none;
    color: #014c8c;
    display: block;
    transition: 0.3s;
    padding-left: 50px;
    cursor: pointer;

}

.sidenav a:hover, .sidenav li:hover, ul#info:hover, ul#linie:hover {
    color: #000;
}

.fa-bars:hover, .fa-times:hover, .fa-caret-down:hover {
    background-color: #025aa5;
}

.hide-sidebar {
    max-height: 52vh;
    position: absolute;
    margin-top: 35px;
}

.show-sidebar {
    max-height: 0px;
    margin-top: 0px;
    position: absolute;
}

.all, .stops {
    -webkit-margin-before: 5px;
}

@media screen and (max-height: 767px) {
    .hide-sidebar {
        max-height: 40vh;
    }

    .sidenav a {
        font-size: 18px;
    }
}

